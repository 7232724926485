import React, { useState } from "react";
import styled from "@emotion/styled";
import Header from "./components/Header";
import bgImage from "./assets/bg-generator.png";
import fish from "./assets/fish.png";

import generateNew from "./assets/generate-new.png";
import download from "./assets/download.png";
const PfpGenerator = () => {
  const [imageIndex, setImageIndex] = useState(Math.floor(Math.random() * 2000));

  const handleGenerateNew = () => {
    const newIndex = Math.floor(Math.random() * 2000);
    setImageIndex(newIndex);
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = require(`./images/${imageIndex}.png`);
    link.download = `PFP-${imageIndex}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Root style={{ backgroundImage: `url("${bgImage}")` }}>
      <div style={{width:'100%'}}>
      <Header />
      </div>
      <div className="content">
        <h2>Pfp Generator</h2>
        <div className="frame-ctr">
          <div className="image">
          <img src={fish} alt="" className="fish"/>
            <img src={require(`./images/${imageIndex}.png`)} alt="Random PFP" className="random" />
          </div>
          <div className="buttons">
            <button className="generate-now" onClick={handleGenerateNew}>
              <img src={generateNew} alt=""/>
            </button>
            <button className="download" onClick={handleDownload}>
                <img src={download} alt=""/>
            </button>
          </div>
        </div>
      </div>
    </Root>
  );
};

const Root = styled("div")`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  animation: fadeIn 1s ease normal forwards 1;

  .content {
    display: flex;
    max-width: 1420px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0;
  }

  h2 {
    margin: 0;
    margin-bottom: 80px;
    color: #FFD953;
    font-weight: 300;
    font-size: 32px;
  }

  h4 {
    margin: 0;
    margin-bottom: 40px;
    color: #fff;
    font-weight: 300;
    font-size: 18px;
  }

  .frame-ctr {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    position:relative;
   

     .fish{
      width:110px;
      top:-20px;
      position:absolute;
      left:50%;
      transform:translateX(-50%);
    }

    .image{
      width:300px;
      height:300px;
      border-radius:50%;
      overflow:hidden;
      padding:5px;
      border:1px solid #39395B;
      
    }

    .random{
      width:100%;
      height:100%;
      border-radius:50%;
      object-fit:cover;

    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top:50px;

      button {
        padding: 0;
        margin-bottom: 15px;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        transition: transform 0.2s ease-in-out;

        &:active {
          transform: scale(0.8); // Tıklandığında küçültme efekti
        }

        img {
          height:50px;
        }
      }
    }
  }
`;

export default PfpGenerator;
