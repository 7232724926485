import styled from "@emotion/styled";
import generateNow from "../assets/generate-now.png";
import generateNowRight from "../assets/generate-now-right.png";

const PlayContent = () => {
    return (
        <Root>
            <div className="left">
                <h3>
                    Generate YOUR OWN CATZ
                </h3>
                <p>
                    Generate your own unique PixelCatz. Once you're happy with your design, download it to use and share. Start generating your own PixelCatz today!
                </p>
                <a href="/generator">
                    <img src={generateNow} alt="" />
                </a>
            </div>
            <div className="right">
                <img src={generateNowRight} alt="" />
            </div>
        </Root>
    )
}

const Root = styled('div')`
    max-width: 1415px;
    margin: 0 auto;
    padding: 20px;
    display:flex;
    justify-content:center;
    margin-top:100px;
    align-items:center;
    flex-direction:column;

    @media(min-width:992px){
        justify-content:space-between;
        margin-top:50px;
        align-items:center;
        flex-direction:row;

    }

    & .left{
        max-width:570px;
        justify-content:flex-start;
        align-items:flex-start;

        & h3{
            font-size:30px;
            color:#FFD953;
            line-height:1.3;
            margin:0;
            margin-bottom:20px;
            font-weight:300;
        }

        & p{
            font-size:14px;
            color:#828282;
            line-height:1.3;
            margin:0;
            margin-bottom:20px;
            font-weight:300;
        }

         & a{
            height:40px;
            display:inline-block;

            img{
                height:100%;
            }
        }
    }

    & .right{
        padding-left:40px;
                padding:0;
        display:flex;
        justify-content:center;
        align-items:center;

        img{
            width:100%;
            max-width:640px;
        }

        @media(min-width:992px){

            & .right{
        padding-left:40px;
            }
        }
    }


    
`

export default PlayContent;
